import TextInput from "@/components/text-input";
import { Button } from "@/components/ui/button";
import InputError from "@/components/input-error";
import { Label } from "@/components/ui/label";
import { useForm } from "@inertiajs/react";
import { FormEventHandler } from "react";

export default function PaymentLoginForm() {
    const { data, setData, post, processing, errors, reset } = useForm({
        owner_id: "",
        credit_pass: "",
    });

    const handleSubmit: FormEventHandler = (e) => {
        e.preventDefault();

        post(route("payment-login.post"), {
            onFinish: () => reset("credit_pass"),
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <Label htmlFor="owner_id">
                    ID Printed on all ONYX Invoices (i.e. A----D---- )
                </Label>

                <TextInput
                    id="owner_id"
                    type="text"
                    name="owner_id"
                    value={data.owner_id}
                    placeholder="Customer ID"
                    className="mt-1 block w-full"
                    isFocused={true}
                    onChange={(e) => setData("owner_id", e.target.value)}
                />

                <InputError message={errors.owner_id} className="mt-2" />
            </div>

            <div className="mt-4">
                <Label htmlFor="credit_pass">
                    Code printed on all ONYX Invoices
                </Label>

                <TextInput
                    id="credit_pass"
                    type="text"
                    name="credit_pass"
                    placeholder="Credit Pass"
                    className="mt-1 block w-full"
                    onChange={(e) => setData("credit_pass", e.target.value)}
                />

                <InputError message={errors.credit_pass} className="mt-2" />
            </div>

            <div className="justify-end mt-4">
                <Button disabled={processing}>Log in</Button>
            </div>
        </form>
    );
}
