import { useForm } from "@inertiajs/react";
import { FormEventHandler } from "react";
import { Label } from "@/components/ui/label";
import InputError from "@/components/input-error";
import TextInput from "@/components/text-input";
import { Button } from "@/components/ui/button";

export default function LoginForm() {
    const { data, setData, post, processing, errors, reset } = useForm({
        username: "",
        password: "",
    });

    const handleSubmit: FormEventHandler = (e) => {
        e.preventDefault();

        post(route("login"), {
            onFinish: () => reset("password"),
        });
    };

    return (
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <fieldset>
                <Label htmlFor="username">Username</Label>

                <TextInput
                    id="username"
                    type="text"
                    name="username"
                    value={data.username}
                    placeholder="Login ID"
                    className="mt-1 block w-full"
                    autoComplete="username"
                    isFocused={true}
                    onChange={(e) => setData("username", e.target.value)}
                />

                <InputError message={errors.username} className="mt-2" />
            </fieldset>

            <fieldset>
                <Label htmlFor="password">Password</Label>

                <TextInput
                    id="password"
                    type="password"
                    name="password"
                    placeholder="Password"
                    className="mt-1 block w-full"
                    autoComplete="current-password"
                    onChange={(e) => setData("password", e.target.value)}
                />

                <InputError message={errors.password} className="mt-2" />
            </fieldset>

            <fieldset className="justify-end">
                <Button disabled={processing}>Log in</Button>
            </fieldset>
        </form>
    );
}
